<template>
    <div class="sim-cart-phase-progress">
        <template v-for="(phase, index) in visiblePhases" :key="index">
            <BaseUiButton
                :disabled="!canSwitchTo(phase)"
                :to="localePath({ name: phase.routeName })"
                variant="bare"
                size="none"
            >
                <CartPhaseIndicator
                    :icon="phase.icon"
                    :label="$t(phase.label ?? '')"
                    :filled="isPhaseActive(phase) || isPhaseBeforeActive(phase)"
                />
            </BaseUiButton>

            <span v-if="index < visiblePhases.length - 1" class="sim-cart-phase-progress__bar" />
        </template>
    </div>
</template>

<script lang="ts" setup>
const localePath = useLocalePath()
const { visiblePhases, isPhaseActive, isPhaseBeforeActive, canSwitchTo } = useCart()

</script>

<style lang="scss" scoped>
.sim-cart-phase-progress {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.sim-cart-phase-progress__bar {
    height: 0.1125rem;
    flex-grow: 1;
    margin-top: 1.0625rem;
    border-bottom: 0.1125rem dashed $sim-c-basic-500;

    @include more-than(sm) {
        margin-top: 2.0625rem;
    }
}
</style>
