<template>
    <UiCard extra-large>
        <BaseUtilCollapseButton
            v-model="collapseData"
            class="w-full"
        >
            <span class="mr-auto sim-subtitle sim-font-medium">
                {{ $t('cart.summary') }}

                <UiCountIndicator
                    v-if="cart?.hasItems()"
                    class="absolute translate-x-1/2"
                    color="primary"
                    :count="cart.totalAmount"
                />
            </span>
        </BaseUtilCollapseButton>

        <BaseUtilCollapse v-model="collapseData">
            <div>
                <!-- CART ITEMS -->
                <template v-for="cartItem in items" :key="cartItem.id!">
                    <hr class="my-6">
                    <CartItem :cart-item="cartItem" />
                </template>


                <!-- SHIPPING -->
                <template v-if="cart?.shippingMethod">
                    <hr class="my-6">

                    <CartItem>
                        <div class="flex flex-col gap-1">
                            <span class="sim-font-bold">
                                {{ $t('cart.shipping') }}
                            </span>

                            <CoreIconLoadingSpinner v-if="isShippingMethodLoading" extra-small />
                            <template v-else>
                                <span class="sim-text-small text-basic-600">
                                    {{ cart!.shippingMethod.name }}
                                </span>

                                <span v-if="cart.shippingOption" class="sim-text-small text-basic-600">
                                    {{ cart.shippingOption.name }}
                                </span>
                            </template>
                        </div>

                        <div v-if="!isShippingMethodLoading">
                            {{ cart!.shippingMethod.taxedPrice?.formatted }}
                        </div>
                    </CartItem>
                </template>


                <!-- PAYMENT -->
                <template v-if="cart?.paymentMethod">
                    <hr class="my-6">

                    <CartItem>
                        <div class="flex flex-col gap-1">
                            <span class="sim-font-bold">
                                {{ $t('cart.payment') }}
                            </span>

                            <CoreIconLoadingSpinner v-if="isPaymentMethodLoading" extra-small />
                            <span v-else class="sim-text-small text-basic-600">
                                {{ cart!.paymentMethod.name }}
                            </span>
                        </div>

                        <div v-if="!isPaymentMethodLoading">
                            {{ cart!.paymentMethod.taxedPrice?.formatted }}
                        </div>
                    </CartItem>
                </template>

                <!-- TOTAL -->
                <hr class="my-6">
                <CartItem>
                    <CartTotal type="summary" />
                </CartItem>
            </div>
        </BaseUtilCollapse>
    </UiCard>
</template>

<script lang="ts" setup>
const { cart, items, fetchItems, isShippingMethodLoading, isPaymentMethodLoading } = useCart()

const { collapseData } = useCollapse({
    expandedBreakpoint: 'lg',
})

await fetchItems()

</script>

<style lang="scss" scoped>

</style>
